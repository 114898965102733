import React from "react";
import { Link } from "react-router-dom";
import "./CleanerOrders.scss";

const CleanerOrders = () => {
  const orders = [
    {
      id: "001",
      type: "Поддерживающая уборка квартиры",
      details: {
        rooms: 2,
        bathrooms: 1,
      },
      extraServices: [
        { name: "Помыть посуду", quantity: 1 },
        { name: "Мойка окон", quantity: 1 },
      ],
      date: "12.01.2025",
      time: "12:30",
      address: {
        city: "Москва",
        street: "Новокосинская дом 44",
        apartment: "00",
        intercom: "00",
        notes: "(-----)",
      },
      client: {
        name: "Анна",
        phone: "+7(989)000-00-00",
      },
      price: 2550,
    },
    {
      id: "0021",
      type: "Генеральная уборка",
      details: {
        rooms: 3,
        bathrooms: 2,
      },
      extraServices: [{ name: "Мойка окон", quantity: 3 }],
      date: "15.01.2025",
      time: "15:00",
      address: {
        city: "Москва",
        street: "Тверская дом 12",
        apartment: "10",
        intercom: "1234",
        notes: "Позвонить за 10 минут",
      },
      client: {
        name: "Олег",
        phone: "+7(912)345-67-89",
      },
      price: 3500,
    },
  ];

  return (
    <div className="order-list">
      <Link to="/cleanermain" className="order-list__back">
        Назад
      </Link>
      <p className="order-list__date">12 сентября</p>
      {orders.map(order => (
        <div key={order.id} className="order-card">
          <div className="order-card__header">Заказ № {order.id}</div>
          <div className="order-card__body">
            <p>{order.type}</p>
            <p>
              {order.details.rooms} - комнатная, {order.details.bathrooms} -
              Сан/Узел
            </p>
            <p>Дополнительные услуги:</p>
            <ul>
              {order.extraServices.map((service, index) => (
                <li key={index}>
                  {service.name} - ({service.quantity})
                </li>
              ))}
            </ul>
            <p>
              Дата уборки: {order.date} в {order.time}
            </p>
            <p>
              Адрес заказа: город - {order.address.city}, улица -{" "}
              {order.address.street}, квартира - {order.address.apartment},
              наличие домофона - {order.address.intercom}
            </p>
            <p>Пожелания по заказу: {order.address.notes}</p>
            <p>
              {order.client.name} <br />
              {order.client.phone}
            </p>
            <p>Работа клинера: {order.price} руб</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CleanerOrders;
