import React, { useState } from "react";
import "./CleanerRevenue.scss";
import { Link } from "react-router-dom";

const CleanerRevenue = () => {
  const revenues = [
    {
      orderId: "001",
      income: "2 550 руб",
      time: "2025-01-12T12:30",
      type: "Квартира",
    },
    {
      orderId: "002",
      income: "3 000 руб",
      time: "2025-01-15T15:00",
      type: "Офис",
    },
    {
      orderId: "003",
      income: "1 800 руб",
      time: "2025-02-10T10:00",
      type: "Дом",
    },
    {
      orderId: "004",
      income: "2 200 руб",
      time: "2025-02-12T11:00",
      type: "Квартира",
    },
    {
      orderId: "005",
      income: "2 900 руб",
      time: "2025-03-05T14:30",
      type: "Офис",
    },
    {
      orderId: "006",
      income: "1 500 руб",
      time: "2025-03-10T09:00",
      type: "Дом",
    },
    {
      orderId: "007",
      income: "2 700 руб",
      time: "2025-04-15T16:00",
      type: "Квартира",
    },
    {
      orderId: "008",
      income: "3 100 руб",
      time: "2025-04-20T13:00",
      type: "Офис",
    },
    {
      orderId: "009",
      income: "1 600 руб",
      time: "2025-05-25T10:30",
      type: "Дом",
    },
    {
      orderId: "010",
      income: "2 300 руб",
      time: "2025-05-30T11:30",
      type: "Квартира",
    },
    {
      orderId: "011",
      income: "3 200 руб",
      time: "2025-06-05T12:30",
      type: "Офис",
    },
    {
      orderId: "012",
      income: "1 700 руб",
      time: "2025-06-10T13:30",
      type: "Дом",
    },
    {
      orderId: "013",
      income: "2 800 руб",
      time: "2025-07-15T14:30",
      type: "Квартира",
    },
    {
      orderId: "014",
      income: "3 300 руб",
      time: "2025-07-20T15:30",
      type: "Офис",
    },
    {
      orderId: "015",
      income: "1 800 руб",
      time: "2025-08-25T16:30",
      type: "Дом",
    },
    {
      orderId: "016",
      income: "2 400 руб",
      time: "2025-08-30T17:30",
      type: "Квартира",
    },
    {
      orderId: "017",
      income: "3 400 руб",
      time: "2025-09-05T18:30",
      type: "Офис",
    },
    {
      orderId: "018",
      income: "1 900 руб",
      time: "2025-09-10T19:30",
      type: "Дом",
    },
    {
      orderId: "019",
      income: "2 500 руб",
      time: "2025-10-15T20:30",
      type: "Квартира",
    },
    {
      orderId: "020",
      income: "3 500 руб",
      time: "2025-10-20T21:30",
      type: "Квартира ",
    },
  ];

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  // Форматирование даты
  const formatDate = time => {
    const date = new Date(time);
    return {
      day: date.getDate(),
      month: months[date.getMonth()],
      year: date.getFullYear(),
      time: date.toTimeString().slice(0, 5),
    };
  };

  // Фильтрация данных
  const filteredRevenues = revenues.filter(revenue => {
    const date = new Date(revenue.time);
    const matchesMonth = selectedMonth
      ? months[date.getMonth()] === selectedMonth
      : true;
    const matchesDay = selectedDay
      ? date.getDate() === Number(selectedDay)
      : true;
    return matchesMonth && matchesDay;
  });

  return (
    <div className="revenue-container">
      <Link to="/cleanermain" className="calendar-container__back">
        Назад
      </Link>
      <h2 className="revenue-container__title">Доходы</h2>

      {/* Фильтры */}
      <div className="revenue-filters">
        <select
          className="revenue-filters__month"
          value={selectedMonth}
          onChange={e => setSelectedMonth(e.target.value)}>
          <option value="">Все месяцы</option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>

        <input
          type="number"
          className="revenue-filters__day"
          placeholder="День"
          min="1"
          max="31"
          value={selectedDay}
          onChange={e => setSelectedDay(e.target.value)}
        />
      </div>

      {/* Таблица */}
      <table className="revenue-table">
        <thead>
          <tr>
            <th>№ заказа</th>
            <th>Доход</th>
            <th>Дата</th>
            <th>Время</th>
            <th>Тип помещения</th>
          </tr>
        </thead>
        <tbody>
          {filteredRevenues.map(revenue => {
            const { day, month, year, time } = formatDate(revenue.time);
            return (
              <tr key={revenue.orderId}>
                <td>{revenue.orderId}</td>
                <td>{revenue.income}</td>
                <td>{`${day} ${month} ${year}`}</td>
                <td>{time}</td>
                <td>{revenue.type}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CleanerRevenue;
