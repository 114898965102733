import React from "react";
import { Link } from "react-router-dom";
import "./CleanerMain.scss";

const CleanerMain = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <Link to="/cleanercalendar" className="dashboard__notification">
          <span className="icon">🔔</span>
          <p>Новый заказ на завтра!</p>
          <span className="count">1</span>
        </Link>
        <div className="dashboard__earnings">
          <span className="icon">💲</span>
          <p>Вы заработали!</p>
          <span className="amount">35 700</span>
        </div>
      </div>
      <div className="dashboard__profile">
        <div className="dashboard__profile-info">
          <p>Клинер - АННА</p>
          <p>№ (001)</p>
        </div>
      </div>
      <div className="dashboard__actions">
        <Link to="/cleanerorders" className="dashboard__button">
          Заказы
        </Link>
        <Link to="/cleanerrevenue" className="dashboard__button">
          Доходы
        </Link>
        <button className="dashboard__button">
          Выбрать выходной или отпуск
        </button>
      </div>
    </div>
  );
};

export default CleanerMain;
