import React, { useState } from "react";
import Calendar from "react-calendar";
import "./CleanerCalendar.scss";
import { Link } from "react-router-dom";

const CleanerCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const formatDate = date => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("ru-RU", options).format(date);
  };

  return (
    <div className="calendar-container">
      <Link to="/cleanermain" className="calendar-container__back">
        Назад
      </Link>
      <p className="calendar-container__date">{formatDate(selectedDate)}</p>
      <Calendar
        locale="ru-RU"
        nextLabel=">"
        prevLabel="<"
        prev2Label={null} // Убираем двойные стрелки
        next2Label={null} // Убираем двойные стрелки
        onChange={date => setSelectedDate(date)} // Обновляем выбранную дату
        value={selectedDate} // Устанавливаем текущую выбранную дату
        className="calendar__services"
      />
    </div>
  );
};

export default CleanerCalendar;
