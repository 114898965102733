import { useEffect, useState } from "react";
import axios from "../../axios";
import "./Cleaners.scss";

const Cleaners = () => {
  const [cleaners, setCleaners] = useState([]);

  useEffect(() => {
    axios
      .get("/api/users", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(response => {
        setCleaners(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className="">
      <section className="">
        <h2 className="page__title">Список заявок от клинеров</h2>
        {cleaners.map((cleaner, index) => (
          <div className="client-card" key={index}>
            <div className="client-card__section">
              <h3 className="client-card__title">Основная информация</h3>
              <p>
                <strong>Имя:</strong> {cleaner.name}
              </p>
              <p>
                <strong>Фамилия:</strong> {cleaner.surname}
              </p>
              <p>
                <strong>Отчество:</strong> {cleaner.patronymic}
              </p>
              <p>
                <strong>Пол:</strong> {cleaner.gender}
              </p>
              <p>
                <strong>Дата рождения:</strong> {cleaner.dateOfBirth}
              </p>
            </div>

            <div className="client-card__section">
              <h3 className="client-card__title">Паспортные данные</h3>
              <p>
                <strong>Серия и номер:</strong> {cleaner.passport}
              </p>
              <p>
                <strong>Дата выдачи:</strong> {cleaner.dateIssue}
              </p>
              <p>
                <strong>Кем выдан:</strong> {cleaner.whomIssued}
              </p>
              <p>
                <strong>Код подразделения:</strong> {cleaner.unitCode}
              </p>
            </div>

            <div className="client-card__section">
              <h3 className="client-card__title">Контактные данные</h3>
              <p>
                <strong>Телефон:</strong> {cleaner.phone}
              </p>
              <p>
                <strong>Email:</strong> {cleaner.email}
              </p>
            </div>

            <div className="client-card__section">
              <h3 className="client-card__title">Адреса</h3>
              <p>
                <strong>Адрес регистрации:</strong>{" "}
                {cleaner.addressOfRegistration} ({cleaner.addressIndex})
              </p>
              <p>
                <strong>Адрес проживания:</strong> {cleaner.addressOfResidence}{" "}
                ({cleaner.addressIndexResidence})
              </p>
            </div>

            <div className="client-card__section">
              <h3 className="client-card__title">Дополнительная информация</h3>
              <p>
                <strong>ИНН:</strong> {cleaner.inn}
              </p>
              <p>
                <strong>СНИЛС:</strong> {cleaner.snils}
              </p>
              <p>
                <strong>Время отправки:</strong>{" "}
                {new Date(cleaner.timestamp).toLocaleString()}
              </p>
            </div>

            <div className="client-card__section">
              <h3 className="client-card__title">Сканы</h3>

              {cleaner.passportScan ? (
                <img
                  src={`https://ipyusupov.ru/${cleaner.passportScan}`} // Замените your-server-address на адрес вашего сервера
                  alt="Скан паспорта"
                  style={{ width: "200px", height: "auto" }} // Добавьте стили, если нужно
                />
              ) : (
                <p>Скан паспорта отсутствует</p>
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Cleaners;
